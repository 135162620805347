.MainSection {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 60px;
    background-color: #aaaaaa;
}

.ButtonBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    display: flex;
    flex-direction: row;
    background-color: #666666;
    padding: 10px;
}

.ButtonBarLeftSection {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.ButtonBarRightSection {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
}