@import "@matillion/component-library";
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/material.css';

.component {

&
:global(.react-codemirror2) {
  height: calc(100% - 32px);
  overflow: auto;

&
:global(.CodeMirror) {

&
:global(.CodeMirror-hscrollbar) {
  left: 0px !important;
}

}
}
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
