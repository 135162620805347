.horizontal {
  border-top: 1px solid black;
  margin-top: 14px;
  width: 10px;
}

.vertical {
  border-right: 1px solid black;
  width: 0px;
  margin-top: 14px;
}

.fullVertical {
  border-right: 1px solid black;
  width: 1px;
  height: 100%;
}

.topVertical {
  border-right: 1px solid black;
  width: 0px;
  height: 15px;
}

.selected {
  border: 1px solid green;
  height: fit-content;
}